/**
 * <ds-nav> –– mobile Navigation handler
 *
 * @module dsNav
 * @summary Handle show / hide mobile navigation defined in <ds-nav> component
 * @version 0.1.0
 * @author Frankie Rapcak <frantisek.rapcak@avast.com>
 * @copyright 1988-2022 © Avast Software PLC
 * @requires cash
 * @todo get rid of cash.js library (use vanilla JS instead?)
 * @todo refactor variable names and usage [documentBodyClassName, isOpenClassName, whichElementOpenedBackdrop]
 */
$(document).ready(function(){
	'use strict';


	var
		$documentBody = $('body'),
		$dsNav = $('.ds-nav.js'),
		$dsNavToggle = $('.ds-nav__toggle.js'),
		$dsBackdrop = $('.ds-backdrop.js'),
		documentBodyClassName = 'ds-nav--is-open',
		isOpenClassName = 'is--open',
		whichElementOpenedBackdrop = 'opened-by--ds-nav';


	//
	// handle show / hide mobile navigation
	//
	if( $dsNavToggle.length > 0 ) {

		$dsNavToggle.on('click', function(){
			$documentBody.toggleClass(documentBodyClassName);
			$(this).toggleClass(isOpenClassName);
			$dsNav.toggleClass(isOpenClassName);
			$dsBackdrop.toggleClass(isOpenClassName);
			$dsBackdrop.toggleClass(whichElementOpenedBackdrop);
		});
	}



	/*

		DEBUG TOOLS
		-----------

		console.info($dsNav.length);
		console.info($dsNavToggle.length);
		console.info($dsBackdrop.length);
		console.log('%c $dsBackdrop.length: ' + $dsBackdrop.length + ' ', 'background: RED; color: WHITE');
		console.info(isOpenClassName);
		console.info(whichElementOpenedBackdrop);

	*/

}); // end of: Document ready
